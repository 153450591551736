import React, { useEffect, useState } from 'react'
import MenuLateral from '../../Components/MenuLateral' 
import Rodape from  '../../Components/Rodape'
import { Alert, Box, Button, Card,  CardActions,  CardContent,  Container,  Dialog,  DialogActions,  DialogContent,  DialogContentText,  DialogTitle,  Fab,  Grid, Snackbar, Typography } from '@mui/material'
import axios from 'axios';
import { format } from 'date-fns';
import { enviroments } from '../../enviroments/enviroments';
import { Add, Delete, Edit, Router } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const Concentrador = () => {
  const navigate = useNavigate();
  const [gateways, setGateways] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [gatewayToDelete, setGatewayToDelete] = useState(null);

  const abrirDialog = () => {
    setOpenDialog(true);
  };

  const fecharDialog = () => {
    setOpenDialog(false);
    setGatewayToDelete(null);
  };

  const visualizarGateways = async () => {
    try {
        const token = localStorage.getItem('token');

        const buscarGateways = await axios.get(`${enviroments.api_backend}/gateways`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

         // Ordena os gateways por nome em ordem alfabética
         const gatewaysOrdenados = buscarGateways.data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
      });

      setGateways(gatewaysOrdenados);

        // Verificar se o gateway parou de transmitir dados
        const now = new Date();

        buscarGateways.data.forEach(gateway => {
            if (gateway.lastSeenAt) {
                const lastSeenAtDate = new Date(gateway.lastSeenAt);
                const diffInSeconds = (now - lastSeenAtDate) / 1000;

                if (diffInSeconds > 450) {
                    setAlerta(true);
                    setTipoAlerta('error');
                    setMensagemAlerta(`O concentrador ${gateway.name} parou de receber dados.`);
                }
            }
        });

        console.log(buscarGateways.data);
    } catch (error) {
        console.log(error);
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta('Erro ao buscar gateways');
    }
  }


  const confirmarExclusao = async () => {
    const token = localStorage.getItem('token');
  
    console.log("Gateway ID:", gatewayToDelete?.gatewayId); // Verifique se o gatewayId está correto
  
    fecharDialog();
    if (!gatewayToDelete) {
      console.log("Nenhum gateway para deletar");
      return;
    }

    try {
      const response = await axios.delete(
        `${enviroments.api_backend}/gateways/${gatewayToDelete.gatewayId}`, 
        {
        headers: {
          'Authorization': `Bearer ${token}`
        }}
      );
      console.log("Resposta da API:", response); // Verifique a resposta da API
    
      setAlerta(true);
      setTipoAlerta('success');
      setMensagemAlerta('Concentrador Deletado');
    
      setTimeout(() => {
        visualizarGateways();
      }, 800);

    } catch (error) {
      console.error("Erro ao deletar concentrador:", error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao deletar concentrador');
    }
  };


  useEffect(() => {
    // Chama a função imediatamente ao carregar o componente
    visualizarGateways();

    // Define o intervalo para chamar a função a cada 30 segundos
    const intervalId = setInterval(visualizarGateways, 20000);

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
  }, []);


  const getStatusColor = (state, lastSeenAt) => {
    if (lastSeenAt) {
      const now = new Date();
      const lastSeenAtDate = new Date(lastSeenAt);
      const diffInSeconds = (now - lastSeenAtDate) / 1000;
  
      // Se o último envio de dados foi há mais de 120 segundos (2 minutos), retorna cinza
      if (diffInSeconds > 600) {
        return '#cccccc'; // Cinza para offline após 2 minutos, independentemente do status
      }
    }
  
    // Se o lastSeenAt está atualizado, retorna a cor baseada no estado
    switch (state) {
      case 'ONLINE':
        return '#00cc60'; // Verde para online
      case 'OFFLINE':
      case 'NEVER_SEEN':
        return '#d3d3d3'; // Cinza claro para offline/never seen
      default:
        return '#cccccc'; // Cor padrão para outros estados
    }
  };
  
  return (
    <>
      <Snackbar
        anchorOrigin={{ 
          vertical: 'top', 
          horizontal: 'right' 
        }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => { }}
      >
        <Alert 
          variant='filled' 
          onClose={
            () => { setAlerta(false) }
          } 
          severity={tipoAlerta}
          sx={{
            fontFamily:'OCR A Extended', 
            whiteSpace: 'pre-line'
          }}
        >
          {mensagemAlerta}
        </Alert>
      </Snackbar>
      <MenuLateral titulo={'Gateway'} icone={<Router />}/>
      
      <Container 
        sx={{   
          mb:10
        }}
      >

      <Fab
        sx={{
          position: 'absolute',
          bottom:95,
          right:16,
          backgroundColor:'#033b57',
          color: '#fff'
        }}
        onClick={
          ()=>navigate('/adicionar-concentrador')
        }
      >
        <Add/>
      </Fab>
      <Grid container spacing={2}>
        {gateways.map((gateway) => (
          <Grid 
            item 
            xs={12} 
            sm={5} 
            md={3} 
            key={gateway.gatewayId} 
          >
            <Card
              sx={{
                height: 380,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'left',
                backgroundColor: 'transparent',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'left',
                  textAlign: 'left',
                }}
              >
                <Typography
                  sx={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    fontFamily: 'OCR A Extended',
                    mb: 3,
                    mt: 0,
                    fontWeight: 'bold',
                  }}
                >
                  {gateway.name}
                </Typography>

                  {/* Rótulo e botão de status */}
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      mb: 1 
                    }}
                  >
                  <Typography 
                    sx={{ 
                      mr: 1, 
                      fontWeight: 'bold', 
                      fontSize: 15, 
                      fontFamily: 'OCR A Extended', 
                    }}
                  >
                    Status:
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      //ACRESCENTEI O GATEWAY.LASTSEENAT
                      backgroundColor: getStatusColor(gateway.state, gateway.lastSeenAt),
                      borderRadius: '50%',
                      width: 20,
                      height: 20,
                      minWidth: 20,
                      p: 0,
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    fontFamily: 'OCR A Extended',
                    mb: 1,
                  }}
                >
                  <span 
                    style={{ 
                      fontWeight: 'bold', 
                      fontSize: 15, 
                      mb: 2, 
                      fontFamily: 'OCR A Extended' 
                    }}
                  >
                    ID:
                  </span>{' '}
                  {gateway.gatewayId}
                </Typography>
  
                <Typography
                  sx={{
                    fontFamily: 'OCR A Extended',
                    mb: 2,
                    width: '100%',
                    fontSize: 15,
                    height: 160,
                    overflow: 'auto',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    
                  }}
                >
                  <span 
                    style={{ 
                      fontWeight: 'bold', 
                      marginRight: '4px', 
                      fontSize: 15, 
                      fontFamily: 'OCR A Extended' 
                    }}
                  >
                    Descrição:
                  </span>
                  {gateway.description}
                </Typography>
                <CardActions
                  sx={{
                    mt: 0,
                    mb: 2,
                    justifyContent: 'center',
                  }}
                >

                  <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    sx={{
                      fontFamily:'OCR A Extended'
                    }}
                    startIcon={<Delete />}
                    onClick={() => {
                      setGatewayToDelete(gateway);
                      abrirDialog();
                    }}
                  >
                    Deletar
                  </Button>
                </CardActions>
                
                
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
      <Rodape />
      {/* Diálogo de confirmação */}
      <Dialog
        open={openDialog}
        onClose={fecharDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle 
          id="alert-dialog-title"
          sx={{ 
            fontFamily: 'OCR A Extended' 
          }} 
        >
          {"Confirmar exclusão do gateway"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText 
            id="alert-dialog-description"
            sx={{
              fontFamily:'OCR A Extended'
            }}
          >
            Deseja realmente excluir o gateway?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={fecharDialog} 
            color="primary"
            sx={{
              fontFamily:'OCR A Extended'
            }}
          >
            Cancelar
          </Button>
          <Button 
            sx={{
              fontFamily:'OCR A Extended'
            }}
            onClick={confirmarExclusao}
            color="error" autoFocus>
          
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      
    </>
  )
}

export default Concentrador