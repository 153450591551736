
import { Navigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';


const RotaPrivada = ({ element }) => {
  

  const token = localStorage.getItem('token');

  if (!token) {
    return (
      <>
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="error">
            Usuário não autenticado
        </Alert>
        <Navigate to="/" replace />;
      </>
    );
  }

  return <>{element}</>;
};

export default RotaPrivada;
