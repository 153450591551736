import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MenuLateral from '../../Components/MenuLateral';
import Rodape from '../../Components/Rodape';
import { Alert, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField, Box, MenuItem, FormControl, Select, Button, Modal, Typography, Divider, Grid, Checkbox, FormGroup, FormControlLabel, RadioGroup, Radio, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress,} from '@mui/material';
import { Container } from '@mui/material';
import jsPDF from 'jspdf';
import { enviroments } from '../../enviroments/enviroments';
import { format } from 'date-fns';
import { Download, FilterAlt, ScreenshotMonitor } from '@mui/icons-material';

const Dados = () => {
  const [devicesLocais, setDevicesLocais] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filtroNome, setFiltroNome] = useState('');
  const [filtroColuna, setFiltroColuna] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [gateways, setGateways] = useState([]);
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  
  const [selectedProperties, setSelectedProperties] = useState({
    bateria: false,
    dendrometro: false,
    fluxo_seiva_1: false,
    fluxo_seiva_2: false,
    sensor_reserva: false,
  });
  
  const [fileType, setFileType] = useState('csv');
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const carregarDados = async () => {
      setLoading(true); // Inicia o carregamento
  
      try {
        await visualizarMetricas();
        await visualizarGateways();
        await visualizarSensores();
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setLoading(false); // Finaliza o carregamento
      }
    };
  
    carregarDados();
  
    // Define os intervalos para atualizações periódicas
    const interval = setInterval(() => {
      visualizarMetricas();
    }, 50000);
  
    const intervalS = setInterval(() => {
      visualizarSensores();
    }, 60000);
  
    const intervalG = setInterval(() => {
      visualizarGateways();
    }, 20000);
  
    // Limpa os intervalos quando o componente for desmontado
    return () => {
      clearInterval(interval);
      clearInterval(intervalS);
      clearInterval(intervalG);
    };
  }, []);

 
  //FUNÇÃO PARA VISUALIZAR GATEWAYS
  const visualizarGateways = async () => {
    try {
      
      const token = localStorage.getItem('token');

      
      const buscarGateways = await axios.get(`${enviroments.api_backend}/gateways`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    
      setGateways(buscarGateways.data);
      console.log(buscarGateways.data);
    } catch (error) {
      console.log(error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao buscar gateways');
    }
  }


 
  //FUNÇÃO PARA VISUALIZAR NÓS SENSORES
  const visualizarSensores = async() =>{
    try {
      const token = localStorage.getItem('token');

      
      const buscarDevices = await axios.get(`${enviroments.api_backend}/devices`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
  
      setDevices(buscarDevices.data);
      console.log(buscarDevices.data);
    } catch (error) {
      console.log(error)
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao buscar devices cadastrados')
    }
  }




// FUNÇÃO PARA VISUALIZAR MÉTRICAS

  const visualizarMetricas = async () => {
    try {
      const token = localStorage.getItem('token');
    
      const response = await axios.get(`${enviroments.api_backend}/metrics`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
    
      console.log('Response:', response.data);

      // Verificar se a resposta é um array
      if (Array.isArray(response.data)) {
        const gateways = response.data;
        let todosDados = [];

        // Iterar sobre cada gateway e coletar as métricas
        gateways.forEach(gateway => {
          const metrics = gateway.metrics;
          todosDados = [...todosDados, ...metrics]; // Concatenar todas as métricas
        });
      
        // Filtrar os dados para remover registros com lastSeenAt nulo
        const dadosValidos = todosDados.filter(dado => dado.lastSeenAt);

        // Ordenar os dados pelo lastSeenAt em ordem decrescente (mais recente primeiro)
        const dadosOrdenados = dadosValidos.sort((a, b) => new Date(b.lastSeenAt) - new Date(a.lastSeenAt));
      
        // Remover registros duplicados com base em devAddr e lastSeenAt
        const dadosUnicos = [];
        const seen = new Set();

        dadosOrdenados.forEach((dado) => {
          const key = `${dado.devAddr}_${dado.lastSeenAt}`;
          if (!seen.has(key)) {
            seen.add(key);
            dadosUnicos.push(dado);
          }
        });

        // Selecionar os últimos 70 registros
        const ultimosRegistros = dadosUnicos.slice(0, 70);
      
        // Atualizar o estado com os últimos registros
        setDevicesLocais(ultimosRegistros);
      } else {
        throw new Error('A resposta não é um array válido.');
      }
    } catch (error) {
      console.error('Erro ao atualizar métricas:', error);
      setAlerta(true);
      setTipoAlerta('error');
      setMensagemAlerta('Erro ao atualizar métricas');
    }
  };


/*const visualizarMetricas = async () => {
  try {
    const token = localStorage.getItem('token');
    
    const response = await axios.get(`${enviroments.api_backend}/metrics`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    console.log('Response:', response.data);

    // Verificar se a resposta é um array
    if (Array.isArray(response.data)) {
      const gateways = response.data;
      let todosDados = [];

      // Iterar sobre cada gateway e coletar as métricas
      gateways.forEach(gateway => {
        const metrics = gateway.metrics;
        todosDados = [...todosDados, ...metrics]; // Concatenar todas as métricas
      });
      
      // Ordenar os dados pelo lastSeenAt em ordem decrescente (mais recente primeiro)
      const dadosOrdenados = todosDados.sort((a, b) => new Date(b.lastSeenAt) - new Date(a.lastSeenAt));
      
      // Remover registros duplicados com base em devAddr e lastSeenAt
      const dadosUnicos = [];
      const seen = new Set();

      dadosOrdenados.forEach((dado) => {
        const key = `${dado.devAddr}_${dado.lastSeenAt}`;
        if (!seen.has(key)) {
          seen.add(key);
          dadosUnicos.push(dado);
        }
      });

      // Selecionar os últimos 70 registros
      const ultimosRegistros = dadosUnicos.slice(0, 70);
      
      // Atualizar o estado com os últimos registros
      setDevicesLocais(ultimosRegistros);
    } else {
      throw new Error('A resposta não é um array válido.');
    }
  } catch (error) {
    console.error('Erro ao atualizar métricas:', error);
    setAlerta(true);
    setTipoAlerta('error');
    setMensagemAlerta('Erro ao atualizar métricas');
  }
};*/

  const abrirDialog = () => {
    setOpenDialog(true);
  };

  const fecharDialog = () => {
    setOpenDialog(false);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFiltroNomeChange = (event) => {
    setFiltroNome(event.target.value);
  };

  const handleFiltroColunaChange = (event) => {
    setFiltroColuna(event.target.value);
    setFiltroNome('');
  };

  const handleOpenModal = () => {
    setSelectedDevices([]);
    setSelectedProperties({
      bateria: false,
      dendrometro: false,
      fluxo_seiva_1: false,
      fluxo_seiva_2: false,
      sensor_reserva: false,

    });
    setFileType('csv');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleToggleDevice = (devAddr) => {
    const currentIndex = selectedDevices.indexOf(devAddr);
    const newSelectedDevices = [...selectedDevices];
  
    if (devAddr === 'todos') {
      if (currentIndex === -1) {
        // Selecionar todos os dispositivos
        newSelectedDevices.push('todos');
      } else {
        // Desmarcar todos os dispositivos
        newSelectedDevices.splice(currentIndex, 1);
      }
    } else {
      // Selecionar dispositivo específico pelo devAddr
      if (currentIndex === -1) {
        newSelectedDevices.push(devAddr);
      } else {
        newSelectedDevices.splice(currentIndex, 1);
      }
  
      // Remover 'todos' se estiver presente
      if (newSelectedDevices.includes('todos')) {
        const filteredDeviceIds = newSelectedDevices.filter(id => id !== 'todos');
        setSelectedDevices(filteredDeviceIds); // Atualizar sem 'todos'
        return; // Retorna para evitar adicionar 'devAddr' depois de limpar 'todos'
      }
    }
  
    setSelectedDevices(newSelectedDevices);
  };

  const handleToggleProperty = (property) => {
    if (property === 'todas as métricas') {
      const allSelected = Object.keys(selectedProperties).every(prop => selectedProperties[prop]);
      const newSelectedProperties = {
        bateria: !allSelected,
        dendrometro: !allSelected,
        fluxo_seiva_1: !allSelected,
        fluxo_seiva_2: !allSelected,
        sensor_reserva: !allSelected,
      };
      setSelectedProperties(newSelectedProperties);
    } else {
      setSelectedProperties(prevSelectedProperties => ({
        ...prevSelectedProperties,
        [property]: !prevSelectedProperties[property],
      }));
    }
  };
  
  /*const handleToggleDevice = (devAddr) => {
    const currentIndex = selectedDevices.indexOf(devAddr);
    const newSelectedDevices = [...selectedDevices];
  
    if (devAddr === 'todos') {
      if (currentIndex === -1) {
        // Selecionar todos os dispositivos
        newSelectedDevices.push('todos');
      } else {
        // Desmarcar todos os dispositivos
        newSelectedDevices.splice(currentIndex, 1);
      }
    } else {
      // Selecionar dispositivo específico pelo devEui
      if (currentIndex === -1) {
        newSelectedDevices.push(devAddr);
      } else {
        newSelectedDevices.splice(currentIndex, 1);
      }
  
      // Remover 'todos' se estiver presente, pois estamos selecionando dispositivos individuais agora
      if (newSelectedDevices.includes('todos')) {
        const filteredDeviceIds = newSelectedDevices.filter(id => id !== 'todos');
        setSelectedDevices(filteredDeviceIds);
      }
    }
  
    setSelectedDevices(newSelectedDevices);
  };
  

  const handleToggleProperty = (property) => {
    if (property === 'todas as métricas') {
      const allSelected = Object.keys(selectedProperties).every(prop => selectedProperties[prop]);
     //ALTERAR PARA ARQUIVO DE DOWNLOAD
      setSelectedProperties({
        bateria: !allSelected,
        dendrometro: !allSelected,
        fluxo_seiva_1: !allSelected,
        fluxo_seiva_2: !allSelected,
        sensor_reserva: !allSelected,
      });
    } else {
      setSelectedProperties({ ...selectedProperties, [property]: !selectedProperties[property] });
    }
  };*/
  

  const downloadCSV = (data, filename) => {
    let csvContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        csvContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        csvContent = [
            ['Gateway', 'Nó Sensor', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


  const downloadTXT = (data, filename) => {
    let txtContent;
    
    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        txtContent = '';
    } else {
        // Cabeçalhos e dados normais quando há dados
        txtContent = [
            ['Gateway', 'Nó Sensor', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])],
            ...data.map(device => [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                ...Object.keys(selectedProperties).filter(key => selectedProperties[key]).map(key => device[key] || '')
            ])
        ].map(e => e.join(',')).join('\n');
    }

    const blob = new Blob([txtContent], { type: 'text/plain;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = (data, filename) => {
    const doc = new jsPDF({ orientation: 'landscape' });
    const fontSize = 7; // Ajuste o tamanho da fonte conforme necessário
    doc.setFontSize(fontSize);
    let y = 10; // Posição inicial vertical

    // Definindo os cabeçalhos
    const headers = ['Gateway', 'Nó Sensor', 'Endereço do Dispositivo', 'Último Dado Recebido', ...Object.keys(selectedProperties).filter(key => selectedProperties[key])];

    if (data.length === 0) {
        // Cabeçalhos vazios quando não há dados
        doc.text('', 10, y);
    } else {
        // Adicionando cabeçalhos no PDF
        const headerText = headers.join(', ');
        const splitHeaderText = doc.splitTextToSize(headerText, 270);
        doc.text(splitHeaderText, 10, y);
        y += (splitHeaderText.length * fontSize) + 5; // Espaço após os cabeçalhos

        // Adicionando dados no PDF
        data.forEach((device) => {
            // Criar uma linha para os dados
            const properties = Object.keys(selectedProperties).filter(prop => selectedProperties[prop])
                .map(prop => device[prop] || '').join(', ');
            const line = [
                device.gatewayName,
                device.name,
                device.devAddr,
                device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : '',
                properties
            ].join(', ');

            // Quebrar o texto em várias linhas, se necessário
            const splitText = doc.splitTextToSize(line, 270); // Ajuste a largura conforme necessário

            if (y + (splitText.length * fontSize) > doc.internal.pageSize.height) {
                doc.addPage(); // Adiciona uma nova página se necessário
                y = 10; // Reseta a posição vertical para a nova página
            }

            doc.text(splitText, 10, y);
            y += (splitText.length * fontSize) + 5; // Ajuste o espaçamento entre linhas
        });
    }

    doc.save(`${filename}.pdf`);
  };


const handleDownload = () => {
  let dataToDownload = devicesLocais;

  // Verificar se nenhum filtro foi selecionado
  const noDevicesSelected = selectedDevices.length === 0 || (selectedDevices.length === 1 && selectedDevices.includes('todos'));
  const noPropertiesSelected = !Object.values(selectedProperties).some(value => value);

  // Se todos os dispositivos e todas as propriedades estão selecionados, não deve filtrar nada
  if (selectedDevices.includes('todos') && Object.values(selectedProperties).every(value => value)) {
    // Não faz nada, mantemos todos os dados
    dataToDownload = devicesLocais;
  }
  
  // Caso contrário, se nenhum dispositivo e nenhuma propriedade forem selecionados, limpa os dados
  else if (noDevicesSelected && noPropertiesSelected) { 
      setAlerta(true);
      setTipoAlerta('info');
      setMensagemAlerta('O arquivo está vazio, preencha todos os campos obrigatórios')
    dataToDownload = []; // Define os dados como vazio
  }

  // Todos os dispositivos e nenhuma propriedade selecionada: O arquivo deve estar vazio
  else if (selectedDevices.includes('todos') && noPropertiesSelected) {
      setAlerta(true);
      setTipoAlerta('info');
      setMensagemAlerta('O arquivo está vazio, preencha todos os campos obrigatórios')
      dataToDownload = [];
  }

  // Nenhum dispositivo e todas as propriedades selecionadas: O arquivo deve estar vazio
  else if (noDevicesSelected && Object.values(selectedProperties).every(value => value)) {
      setAlerta(true);
      setTipoAlerta('info');
      setMensagemAlerta('O arquivo está vazio, preencha todos os campos obrigatórios')
      dataToDownload = [];
  }

  // Um dispositivo sem propriedades selecionadas: O arquivo deve estar vazio
  else if (selectedDevices.length === 1 && !selectedDevices.includes('todos') && noPropertiesSelected) {
      setAlerta(true);
      setTipoAlerta('info');
      setMensagemAlerta('O arquivo está vazio, preencha todos os campos obrigatórios')
      dataToDownload = [];
  }

  // **Nova condição:**
  // Quando qualquer métrica ou propriedade estiver selecionada, mas nenhum dispositivo foi selecionado (nenhum device)
  else if (noDevicesSelected && !noPropertiesSelected) {
      dataToDownload = [];  // O arquivo deve estar vazio
  }
  
  else {
      // Aplicar filtro de dispositivos selecionados
      if (selectedDevices.length > 0 && !selectedDevices.includes('todos')) {
          dataToDownload = dataToDownload.filter(device => selectedDevices.includes(device.devAddr));
      }

      // Aplicar filtro de propriedades selecionadas
      dataToDownload = dataToDownload.map(device => {
          const filteredDevice = { name: device.name, devEui: device.devEui, devAddr: device.devAddr, lastSeenAt: device.lastSeenAt, gatewayName: device.gatewayName };

          Object.entries(selectedProperties).forEach(([key, value]) => {
              if (value) filteredDevice[key] = device[key];
          });

          return filteredDevice;
      });
  }

  console.log('Dados para download:', dataToDownload);

  if (fileType === 'csv') {
      downloadCSV(dataToDownload, 'Dados');
  } else if (fileType === 'txt') {
      downloadTXT(dataToDownload, 'Dados');
  } else if (fileType === 'pdf') {
      downloadPDF(dataToDownload, 'Dados');
  }

  handleCloseModal();
};









/*const handleDownload = () => {
  let dataToDownload = devicesLocais;

  // Verificar se nenhum filtro foi selecionado
  const noDevicesSelected = selectedDevices.length === 0 || (selectedDevices.length === 1 && selectedDevices.includes('todos'));
  const noPropertiesSelected = !Object.values(selectedProperties).some(value => value);

  if (noDevicesSelected && noPropertiesSelected) {
      dataToDownload = []; // Define os dados como vazio
  } else {
      // Aplicar filtro de dispositivos selecionados
      if (selectedDevices.length > 0 && !selectedDevices.includes('todos')) {
          dataToDownload = dataToDownload.filter(device => selectedDevices.includes(device.devAddr));
      }

      // Aplicar filtro de propriedades selecionadas
      dataToDownload = dataToDownload.map(device => {
          const filteredDevice = { name: device.name, devEui: device.devEui, devAddr: device.devAddr, lastSeenAt: device.lastSeenAt, gatewayName: device.gatewayName };

          Object.entries(selectedProperties).forEach(([key, value]) => {
              if (value) filteredDevice[key] = device[key];
          });

          return filteredDevice;
      });
  }

  console.log('Dados para download:', dataToDownload);

  if (fileType === 'csv') {
      downloadCSV(dataToDownload, 'Dados');
  } else if (fileType === 'txt') {
      downloadTXT(dataToDownload, 'Dados');
  } else if (fileType === 'pdf') {
      downloadPDF(dataToDownload, 'Dados');
  }

  handleCloseModal();
};*/

const handleResetFiltro = () => {
  setFiltroColuna('');  // Reseta a coluna de filtro
  setFiltroNome('');    // Reseta o nome do filtro
};


  const filteredDevices = devicesLocais.reduce((acc, current) => {
    const x = acc.find(device => device.name === current.name);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, devicesLocais.length - page * rowsPerPage);
  const confirmarExclusao = async () => {
    fecharDialog();
  
    try {
      const token = localStorage.getItem('token');
      // Primeira requisição para excluir o nó sensor
      const response = await axios.delete(`${enviroments.api_backend}/metrics`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      setAlerta(true);
      setTipoAlerta('success');
      setMensagemAlerta('Dados deletados');
      visualizarMetricas();
  
    } catch (error) {
      console.log(error);
  
      if (error.response && error.response.status === 404) {
        setAlerta(true);
        setTipoAlerta('info'); // Pode usar 'info' para mensagens informativas
        setMensagemAlerta('Nenhuma métrica recebida');
      } else {
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta('Erro ao deletar Dados');
      }
    }
  };
  

  return (
    <>
        <MenuLateral titulo={'Dados'} icone={<ScreenshotMonitor/>}/>
          {loading ? (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
          <CircularProgress />
        </Box>
        
      ) : (
        <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alerta}
        autoHideDuration={6000}
        onClose={() => setAlerta(false)}
      >
        <Alert 
          variant='filled' 
          onClose={() => setAlerta(false)} 
          severity={tipoAlerta}
          sx={{fontFamily:'OCR A Extended', whiteSpace: 'pre-line'}}
        >
          {mensagemAlerta}
        </Alert>
      </Snackbar>

      

      <Container sx={{ mt: 7, mb:10, }}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <FormControl sx={{ minWidth: 80 }}>
          <Select
            value={filtroColuna}
            onChange={handleFiltroColunaChange}
            displayEmpty
            inputProps={{ 
              'aria-label': 'Escolher coluna de filtro' 
            }}
            sx={{ 
              height: 40, 
              fontFamily:'OCR A Extended' 
            }}
          >
            {/* Habilitar o clique no ícone e chamando a função de reset */}
            <MenuItem
              value="" 
              onClick={handleResetFiltro} // Chama a função de reset
            >
              <FilterAlt 
                style={{ 
                  marginRight: 8, 
                  cursor: 'pointer' 
                }} 
              />
        
            </MenuItem>
            <MenuItem 
              value="gatewayName" 
              sx={{ 
                fontFamily: 'OCR A Extended' 
              }}
            >
              Gateway
            </MenuItem>
            <MenuItem 
              value="name" 
              sx={{ 
                fontFamily: 'OCR A Extended' 
              }}
            >
              Nó Sensor
            </MenuItem>
            <MenuItem 
              value="devAddr" 
              sx={{ 
                fontFamily: 'OCR A Extended' 
              }}
            >
              ID
            </MenuItem>
          </Select>
        </FormControl>

        {filtroColuna && (
          <TextField
            id="filtro-nome"
            label="Filtrar"
            variant="outlined"
            value={filtroNome}
            InputLabelProps={{
              sx: {
                fontFamily: 'OCR A Extended',
                justifyContent: 'center',
                alignItems: 'center',
                top: '50%',
                transform: 'translateY(-50%)',  // Centraliza o label quando o campo não está focado
                transition: 'all 0.2s ease-out',  // Suaviza a transição
                '&.MuiInputLabel-shrink': {
                  top: '-6px',  // Ajusta a posição quando o label vai para o topo
                  transform: 'translateY(0)',  // Remove a translação ao focar
                  fontSize: '9.8px',  // Diminui o tamanho do texto quando está no topo
                left:'10px'
                }
              }
            }}
            onChange={handleFiltroNomeChange}
            sx={{
              marginLeft: 2,
              width: 170,
              fontFamily: 'OCR A Extended',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            InputProps={{
              sx: {
                height: 40,  // Ajusta a altura do campo
                padding: 0,
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiInputBase-input': {
                  height: 40,
                  padding: '0 14px',
                  fontFamily: 'OCR A Extended',
                  alignItems: 'center',
                },
              },
            }}
          />
        )}
      </Box>


      <TableContainer component={Paper} xs={12} md={6}  >
        <Table>
          <TableHead>
            <TableRow>
              {/*<TableCell sx={{ fontWeight:'bold', fontFamily:'Roboto', textAlign: 'center'}} >Concentrador</TableCell>*/}
              <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Gateway</TableCell>
              <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Nó sensor</TableCell>
              <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >ID</TableCell>
              <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Bateria</TableCell>
              <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Dendrômetro</TableCell>
              <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >FS1</TableCell>
              <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >FS2</TableCell>
              <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Reserva</TableCell>
              <TableCell sx={{ fontWeight:'bold', fontFamily:'OCR A Extended', textAlign: 'center'}} >Data/Hora</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
            (
              rowsPerPage > 0
              ? devicesLocais.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : devicesLocais
            )
            .filter(device => 
            !filtroColuna || 
            (device[filtroColuna] && device[filtroColuna].toString().toLowerCase().includes(filtroNome.toLowerCase()))
            )
            .map((device) => (
              <TableRow key={device.id}>
                {/*<TableCell sx={{ fontFamily:'Roboto', textAlign: 'center'}}>{device.gatewayName}</TableCell>*/}
                <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.gatewayName}</TableCell>
                <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.name}</TableCell>
                <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.devAddr}</TableCell>
                <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.bateria}</TableCell>
                <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.dendrometro}</TableCell>
                <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.fluxo_seiva_1}</TableCell>
                <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.fluxo_seiva_2}</TableCell>
                <TableCell sx={{ fontFamily:'OCR A Extended', textAlign: 'center'}}>{device.sensor_reserva}</TableCell>
                <TableCell sx={{ fontFamily: 'OCR A Extended', textAlign: 'center' }}>
                  {device.lastSeenAt ? format(new Date(device.lastSeenAt), 'dd/MM/yyyy HH:mm:ss') : ''}
                </TableCell>
              </TableRow>
            )
            )
          }
          {/*{emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={9} />
              </TableRow>
          )}*/}

          {emptyRows > 0 &&
            Array.from({ length: emptyRows }).map((_, index) => (
              <TableRow key={`empty-${index}`} style={{ height: 53 }}>
                <TableCell colSpan={9} />
              </TableRow>
            )
          )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={devicesLocais.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
        <Box 
          sx={{ 
            mt: 2, 
            display: 'flex', 
            justifyContent: 'center',
            gap:2 
          }}
        >
          <Button 
            variant="contained" 
            onClick={handleOpenModal} 
            sx={{
              backgroundColor: '#033b57', 
              height:55, 
              fontFamily:'OCR A Extended', 
              width: 350,
            }} 
            startIcon={<Download/>}
          >
            Download
          </Button>
          {/*<Button 
            variant="contained" 
            onClick={abrirDialog} 
            sx={{
              backgroundColor: '#033b57', 
              height:55, 
              fontFamily:'OCR A Extended', 
              width: 350,
            }} 
            startIcon={<Download/>}
          >
            Limpar Registro de Dados
          </Button>*/}
        </Box>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box 
            sx={{ 
              width: 600, 
              bgcolor: 'background.paper', 
              boxShadow: 24, 
              p: 4 
            }}
          >
            <Typography variant="h6" id="modal-title" gutterBottom sx={{fontFamily:'OCR A Extended'}}>
              Selecione os nós sensores e os parâmetros desejados:
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography 
                variant="subtitle1" 
                gutterBottom 
                sx={{
                  fontFamily:'OCR A Extended'
                }}
              >
                Nós sensores
              </Typography>
              <div 
                style={{ 
                  maxHeight: '300px', 
                  overflowY: 'auto', 
                  fontFamily: 'OCR A Extended' 
                }}
              >
                <FormGroup 
                  sx={{ 
                    fontFamily: 'OCR A Extended' 
                  }}
                >
                  {[
                    { devAddr: 'todos', name: 'Todos os Nós sensores' },
                    ...filteredDevices
                    ].map(device => (
                      <FormControlLabel
                        key={device.devAddr} // Usamos devEui como chave única
                        control={
                          <Checkbox
                            checked={selectedDevices.includes(device.devAddr)}
                            onChange={() => handleToggleDevice(device.devAddr)}
                            sx={{ 
                              '&.Mui-checked': { 
                              color: '#033b57' 
                            } 
                          }} // Opcional: altera a cor do checkbox selecionado
                        />
                      }
                      label={
                        <span 
                          style={{ 
                            fontFamily: 'OCR A Extended' 
                          }}
                        >
                          {device.name}
                        </span>
                      } // Aplica a fonte ao label
                        sx={{ 
                          fontFamily: 'OCR A Extended' 
                        }} // Aplica a fonte ao FormControlLabel
                    />
                ))}
              </FormGroup>
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography 
              variant="subtitle1" 
              gutterBottom 
              sx={{ 
                fontFamily: 'OCR A Extended' 
              }}
            >
              Parâmetros
            </Typography>

            <FormGroup 
              sx={{ 
                fontFamily: 'OCR A Extended' 
              }}
            >
              {[
                'todas as métricas',
                'bateria',
                'dendrometro',
                'fluxo_seiva_1',
                'fluxo_seiva_2',
                'sensor_reserva',
      
              ].map(property => (
                <FormControlLabel
                  key={property}
                  control={
                    <Checkbox
                      checked={
                        selectedProperties[property]
                      }
                      onChange={
                        () => handleToggleProperty(property)
                      }
                      sx={{ 
                        '&.Mui-checked': { 
                          color: '#033b57' 
                        } 
                      }} // Opcional: altera a cor do checkbox selecionado
                    />
                  }
                  label={
                    <span 
                      style={{ 
                        fontFamily: 'OCR A Extended' 
                      }}
                    >
                      {property === 'fluxo_seiva_1'
                      ? 'Fluxo de Seiva 1'
                      : property === 'fluxo_seiva_2'
                      ? 'Fluxo de Seiva 2'
                      : property === 'dendrometro'
                      ? 'Dendrômetro'
                      : property === 'bateria'
                      ? 'Bateria'
                      : property === 'sensor_reserva'
                      ? 'Sensor Reserva'
                      : property === 'todas as métricas'
                      ? 'Todos os parâmetros'
                      : property}
                    </span>
                  }
                  sx={{ 
                    fontFamily: 'OCR A Extended' 
                  }} // Aplica a fonte ao FormControlLabel
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      <Typography 
        variant="subtitle1" 
        gutterBottom 
        sx={{ 
          mt: 2, 
          fontFamily: 'OCR A Extended' 
        }}
      >
        Formato
      </Typography>
      <RadioGroup
        value={fileType}
        onChange={
          (e) => setFileType(e.target.value)
        }
        row
        sx={{ 
          fontFamily: 'OCR A Extended' 
        }}
      >
        <FormControlLabel 
          value="csv" 
          control={<Radio />} 
          label={
            <span 
              style={{ 
                fontFamily: 'OCR A Extended' 
              }}
            >
              CSV
            </span>} 
          sx={{ 
            fontFamily: 'OCR A Extended' 
          }} 
        />
      <FormControlLabel 
        value="txt" 
        control={<Radio />} 
        label={
          <span 
            style={{ 
              fontFamily: 'OCR A Extended' 
            }
          }
            >TXT
          </span>} 
        sx={{ 
          fontFamily: 'OCR A Extended' 
        }} 
      />
      <FormControlLabel 
        value="pdf" 
        control={<Radio />} 
        label={
          <span 
            style={{ 
              fontFamily: 'OCR A Extended' 
            }}
          >
            PDF
          </span>} 
        sx={{ 
          fontFamily: 'OCR A Extended' 
        }} 
      />
    </RadioGroup>

    <Grid container spacing={2} sx={{ mt: 2,  }}>
      <Grid item xs={6}>
        <Button 
          variant='contained' 
          fullWidth onClick={handleDownload} 
          startIcon={<Download/>} 
          sx={{
            backgroundColor:'#033b57', 
            color:'#fff', 
            height:55,
            fontFamily: 'OCR A Extended',
            width: 350,
          }} 
        >
          Download 
        </Button>
      </Grid>        
      </Grid>
          </Box>
            </Modal>
              <Dialog
                open={openDialog}
                onClose={fecharDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle 
                  id="alert-dialog-title" 
                  sx={{ 
                    fontFamily: 'OCR A Extended' 
                  }}
                >
                  {"Deseja realmente excluir todos os dados?"}
                </DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions>
              <Button
                onClick={fecharDialog}
                color="primary"
                sx={{ fontFamily: 'OCR A Extended' }}
              >
                Cancelar
              </Button>
              <Button
                onClick={confirmarExclusao}
                color="primary"
                autoFocus
                sx={{ fontFamily: 'OCR A Extended' }}
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
      </Container>
      </>
            )}
      <Rodape />
    </>
  );
};

export default Dados;

