import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Sair = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const removerToken = () => {
            localStorage.removeItem('token');
            navigate('/');
        };

        removerToken();
    }, [navigate]);

    return null;
}

export default Sair;
