import { createBrowserRouter } from "react-router-dom";
import Sensores from "./Pages/Sensores/Sensores";
import Login from "./Pages/Login/Login";
import RotaPrivada from "./Pages/RotaPrivada/RotaPrivada";
import Sair from "./Pages/Sair/Sair"



import Concentrador from "./Pages/Concentrador/Concentrador";

import Dados from "./Pages/Dados/Dados";
import AdicionarConcentrador from "./Pages/AdicionarConcentrador/AdicionarConcentrador";
import Notificacoes from "./Pages/Notificacoes/Notificacoes";


const routers = createBrowserRouter([
   

    {
        path: '/sensores',
        element: <RotaPrivada element={<Sensores/>}/>
    },
    {
        path: '/concentrador',
        element: <RotaPrivada element={<Concentrador/>}/>
    },
    {
        path: '/dados',
        element: <RotaPrivada element={<Dados/>}/>
    },
    {
        path: '/',
        element: <Login/>
    },
    {
        path: '/sair',
        element: <Sair/>
    },
    {
        path:'/adicionar-concentrador',
        element: <RotaPrivada element={<AdicionarConcentrador/>}/>
    },
    {
        path:'/notificacoes',
        element: <RotaPrivada element={<Notificacoes/>}/>
    }


])

export default routers;