import { ThemeProvider } from '@emotion/react';
import { Box, Container, CssBaseline, Grid, Typography, createTheme } from '@mui/material';
import logoRodape from '../Assets/suzano.svg'; 
import isiRodape from '../Assets/RODAPE-ISI.png';

import React from 'react';

const Rodape = () => {
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#033b57',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CssBaseline />

        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            height: 60,
            mt: 15,
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.primary.main
                : theme.palette.grey[800],
            color: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.common.white,
            position: 'fixed',
            bottom: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',
            flexDirection: 'column', // Ensure everything is centered vertically
          }}
        >
          <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            
            {/* Grid for Images */}
            <Grid container spacing={2} justifyContent="center" sx={{ mb: 0 }}>
              <Grid item>
                <img src={isiRodape} alt="Logo ISI" style={{ width: '130px', height: '30px' }} />
              </Grid>
              <Grid item>
                <img src={logoRodape} alt="Logo Suzano" style={{ width: '80px', height: '34px' }} />
              </Grid>
            </Grid>

            {/* Text below the images */}
            <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold', fontSize: 11, textAlign: 'center', fontFamily: 'OCR A Extended' }}>
              © 2024 Todos os direitos reservados
            </Typography>
            
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Rodape;


{/**import { ThemeProvider } from '@emotion/react';
import { Box, Container, CssBaseline, Typography, createTheme } from '@mui/material';
import logoRodape from '../Assets/suzano.svg'; 

import React from 'react';

const Rodape = () => {
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#13279a',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CssBaseline />

        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            height: 80,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.primary.main
                : theme.palette.grey[800],
            color: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.common.white,
            position: 'fixed',
            bottom: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between', 
            alignItems: 'center', 
          }}
        >
          <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' , justifyContent:'space-around'}}>
            <img src={logoRodape} alt="Logo Rodapé" style={{ width: '80px', height: '40px' }} />

            <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold', fontSize: 15, textAlign: 'center' }}>
              © Suzano S/A - Todos os direitos reservados
            </Typography>
            <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: 11, textAlign: 'center' }}>
              soluções sustentáveis para renovar a vida das pessoas e do planeta a partir da árvore.
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Rodape; */}