import React, { useState } from 'react'
import MenuLateral from '../../Components/MenuLateral'
import Rodape from '../../Components/Rodape'
import { Router, Save } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { enviroments } from '../../enviroments/enviroments';
import { Alert, Button, Container, Divider, Grid, Paper, Snackbar, TextField } from '@mui/material'
import axios from 'axios'

const AdicionarConcentrador = () => {

    const navigate = useNavigate();
    const [alerta, setAlerta] = useState('');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('');
    const [name, setName] = useState('');
    const [gatewayId, setGatewayId] = useState('');
    const [description, setDescription] = useState('');

    const enviarDados = () => {
        try {
            const token = localStorage.getItem('token');
            
            // Verificação correta dos campos obrigatórios
            if (!gatewayId || !name || !description) {
                setAlerta(true);
                setTipoAlerta('error');
                setMensagemAlerta('Insira todos os campos obrigatórios!');
                return; 
            }
    
            const gateway = axios.post(`${enviroments.api_backend}/cadastrarGateways`,{
                    name: name,
                    gatewayId: gatewayId,
                    description: description
                },
                {
                    headers:{
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log(gateway);
            setAlerta(true);
            setTipoAlerta('success');
            setMensagemAlerta('Gateway cadastrado com sucesso!');
            setTimeout(()=>{
                navigate('/concentrador');
            }, 1000);
    
        } catch (error) {
            console.log(error);
            setAlerta(true);
            setTipoAlerta('error');
            setMensagemAlerta('Erro ao cadastrar gateway');
        }
    }
    

  return (
    <>
        <Snackbar
            anchorOrigin={{
                vertical:'top', 
                horizontal:'right'
            }}
            open={alerta}
            autoHideDuration={600}
            onClose={()=>{ }}
        >
            <Alert 
                variant='filled' 
                onClose={
                    ()=>{setAlerta(false)}
                } 
                severity={tipoAlerta}
            >
                {mensagemAlerta}
            </Alert>
        </Snackbar>
        <MenuLateral icone={<Router/>}/>

        <Container 
            sx={{ 
                mt: 5 
            }}
        >
            <Paper 
                sx={{ 
                    maxWidth: 500, 
                    mx: 'auto', 
                    p: 3 
                }}
            >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        label="Nome do gateway"
                        fullWidth
                        inputProps={{ 
                            maxLength: 25,
                            style:{
                                fontFamily:'OCR A Extended'
                            }
                        }}
                        onChange={(e)=>{
                            setName(e.target.value)
                        }}
                        helperText="Este campo é obrigatório"
                        InputLabelProps={{
                            sx:{
                                fontFamily:'OCR A Extended'
                            }
                        }}
                        FormHelperTextProps={{
                            sx:{
                                fontFamily:'OCR A Extended'
                            }
                        }}
                    />
                </Grid>
            
                <Grid item xs={12} >
                    <TextField
                        sx={{
                            mt:1
                        }}
                        label="Gateway ID"
                        fullWidth
                        helperText="Este campo é obrigatório (Insira o GatewayID da etiqueta do gateway)"
                        inputProps={{
                            maxLength: 16,
                            style:{
                                fontFamily:'OCR A Extended'
                            }
                        }}
                        onChange={(e)=>{
                            setGatewayId(e.target.value)
                        }}
                        InputLabelProps={{
                            sx:{
                                fontFamily:'OCR A Extended'
                            }
                        }}
                        FormHelperTextProps={{
                            sx:{
                                fontFamily:'OCR A Extended'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        sx={{mt:1}}
                        label="Descrição"
                        fullWidth
                        inputProps={{ 
                            maxLength: 150,
                            style:{
                                fontFamily:'OCR A Extended'
                            }
                        }}
                        helperText="Neste campo deve ser inserido o código MAC, coordenadas, UNF, Unidade Produtiva, macroambiente, experimento, dentre outros - Este campo é obrigatório)"
                        onChange={(e)=>{
                            setDescription(e.target.value)
                        }}
                        InputLabelProps={{
                            sx:{
                                fontFamily:'OCR A Extended'
                            }
                        }}
                        FormHelperTextProps={{
                            sx:{
                                fontFamily:'OCR A Extended'
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Divider flexItem 
                sx={{ 
                    mt: 3, 
                    mb: 3 
                }} 
            />

            <Button
                variant="contained"
            
                startIcon={<Save/>}
                sx={{
                    backgroundColor: '#033b57',
                    height:55,
                    width:350,
                    fontFamily:'OCR A Extended'
                }}
                onClick={enviarDados}
            >
            Cadastrar
            </Button>              
            </Paper>
        </Container>
        <Rodape/>
    </>
  )
}

export default AdicionarConcentrador