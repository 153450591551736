
import React, { useState, useEffect } from 'react';
import MenuLateral from '../../Components/MenuLateral';
import Rodape from '../../Components/Rodape';
import { Delete, Download, Notifications } from '@mui/icons-material';
import {Container,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TablePagination,Paper,Button,Dialog,DialogTitle,DialogContent,DialogActions,Snackbar,Alert,Box, CircularProgress,} from '@mui/material';
import { enviroments } from '../../enviroments/enviroments';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const Notificacoes = () => {
  const [notificacoes, setNotificacoes] = useState([]);
  const [page, setPage] = useState(0); 
  const [rowsPerPage] = useState(10); 
  const [alerta, setAlerta] = useState(false);
  const [mensagemAlerta, setMensagemAlerta] = useState('');
  const [tipoAlerta, setTipoAlerta] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [notificacaoCache, setNotificacaoCache] = useState({});

  const abrirDialog = () => {
    setOpenDialog(true);
  };

  const fecharDialog = () => {
    setOpenDialog(false);
  };

  const visualizarNotificacoes = async () => {
    try {
      const token = localStorage.getItem('token');
  
      // Faz a requisição para buscar notificações
      const { data: buscarNotificacoes } = await axios.get(
        `${enviroments.api_backend}/notificacoes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Combina todas as notificações de todos os gateways
      const novasNotificacoes = buscarNotificacoes.flatMap((gateway) => gateway.notificacoes);
  
      // Remove duplicatas
      const notificacoesFiltradas = novasNotificacoes.filter((novaNotificacao) => {
        return !notificacoes.some((notificacaoAtual) => {
          return (
            notificacaoAtual.descricao === novaNotificacao.descricao &&
            notificacaoAtual.item === novaNotificacao.item &&
            notificacaoAtual.tipo === novaNotificacao.tipo &&
            notificacaoAtual.createdAt === novaNotificacao.createdAt
          );
        });
      });
  
      // Pega as últimas 70 notificações e reverte a ordem para exibir as mais recentes primeiro
      const ultimasNotificacoes = notificacoesFiltradas
        .slice(-70) // Seleciona os últimos 70 itens
        .reverse(); // Reverte para exibir os mais recentes no topo
  
      console.log('Notificações recebidas:', buscarNotificacoes);
      console.log('Últimas notificações filtradas e ordenadas:', ultimasNotificacoes);
  
      // Atualiza o estado com as notificações não duplicadas e limitadas
      setNotificacoes(ultimasNotificacoes);
    } catch (error) {
      console.error('Erro ao buscar notificações:', error);
    }
  };
  ;
  
  
  
  useEffect(() => {
    const carregarDados = async () => {
      setLoading(true); // Inicia o carregamento

    try {
      await visualizarNotificacoes();

    } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      } finally {
        setLoading(false); // Finaliza o carregamento
    }
  };

    carregarDados();

    const intervalId = setInterval(() => {
      visualizarNotificacoes();
    }, 5000);


    // Limpa os intervalos quando o componente for desmontado
    return () => clearInterval(intervalId);
  }, []);

  // Calcula as notificações a serem exibidas na página atual
  const notificacoesPagina = notificacoes.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Lida com a mudança de página
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const confirmarExclusao = async () => {
    fecharDialog();
  
    try {
      const token = localStorage.getItem('token');
      // Primeira requisição para excluir o nó sensor
      const response = await axios.delete(`${enviroments.api_backend}/notificacoes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      setAlerta(true);
      setTipoAlerta('success');
      setMensagemAlerta('Notificações deletadas');
      visualizarNotificacoes();
  
    } catch (error) {
      console.log(error);
  
      if (error.response && error.response.status === 404) {
        setAlerta(true);
        setTipoAlerta('info'); // Para mensagens informativas
        setMensagemAlerta('Nenhuma notificação encontrada');
      } else {
        setAlerta(true);
        setTipoAlerta('error');
        setMensagemAlerta('Erro ao deletar notificações');
      }
    }
  };
  

  
  const downloadNotificacoes = async () => {
    try {
      const token = localStorage.getItem('token');
  
      // Faz a requisição para buscar notificações
      const { data: buscarNotificacoes } = await axios.get(
        `${enviroments.api_backend}/notificacoes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Combina todas as notificações de todos os gateways
      const novasNotificacoes = buscarNotificacoes.flatMap((gateway) => gateway.notificacoes);
  
      // Remove duplicatas combinando notificações existentes no estado e as novas
      const notificacoesAtuais = [...notificacoes]; // Clona as notificações do estado atual
      const notificacoesFiltradas = novasNotificacoes.filter((novaNotificacao) => {
        return !notificacoesAtuais.some((notificacaoAtual) => {
          return (
            notificacaoAtual.descricao === novaNotificacao.descricao &&
            notificacaoAtual.item === novaNotificacao.item &&
            notificacaoAtual.tipo === novaNotificacao.tipo &&
            notificacaoAtual.createdAt === novaNotificacao.createdAt
          );
        });
      });
  
      // Pega as últimas 70 notificações, unindo as notificações existentes e filtradas
      const todasNotificacoes = [...notificacoesAtuais, ...notificacoesFiltradas];
  
      // Ordena as notificações pela data (mais recentes primeiro)
      const notificacoesOrdenadas = todasNotificacoes.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA; // Ordena do mais recente para o mais antigo
      });
  
      // Define as colunas do CSV
      const headers = ['Gateway', 'Descrição', 'Dispositivo', 'Tipo', 'Data/Hora'];
      const rows = notificacoesOrdenadas.map((notificacao) => [
        notificacao.gatewayNome,
        notificacao.descricao,
        notificacao.item,
        notificacao.tipo,
        notificacao.createdAt
          ? format(new Date(notificacao.createdAt), 'dd/MM/yyyy HH:mm:ss')
          : '', // Formata a data/hora se estiver disponível
      ]);
  
      // Gera o CSV como string
      const csvContent = [
        headers.join(','), // Adiciona os cabeçalhos
        ...rows.map((row) => row.join(',')), // Adiciona cada linha de dados
      ].join('\n');
  
      // Cria o arquivo Blob e inicia o download
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
  
      link.href = url;
      link.setAttribute('download', 'notificacoes.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
    } catch (error) {
      console.error('Erro ao baixar notificações:', error);
    }
  };
  
  
  
  const emptyRows = Math.max(0, rowsPerPage - notificacoesPagina.length);

  return (
    <>
      <MenuLateral icone={<Notifications />} />
      <Rodape />
      {loading ? (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={alerta}
            autoHideDuration={6000}
            onClose={() => setAlerta(false)}
          >
            <Alert
              variant="filled"
              onClose={() => setAlerta(false)}
              severity={tipoAlerta}
              sx={{
                whiteSpace: 'pre-line',
                fontFamily: 'OCR A Extended',
              }}
            >
              {mensagemAlerta}
            </Alert>
          </Snackbar>
          <Container 
            sx={{ 
              mb: 10, 
            }}
          >
            <TableContainer
              component={Paper}
              xs={12}
              md={6}
              sx={{
                width: '100%',
                maxWidth: '100%',
                overflowX: 'auto',
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ 
                        ontFamily: 'OCR A Extended', 
                        fontWeight: 'bold', 
                        textAlign: 'center' 
                      }}
                    >
                      Gateway
                    </TableCell>
                    <TableCell
                      sx={{ 
                        fontFamily: 'OCR A Extended', 
                        fontWeight: 'bold', 
                        textAlign: 'center' 
                      }}
                    >
                      Descrição
                    </TableCell>
                    <TableCell
                      sx={{ 
                        fontFamily: 'OCR A Extended', 
                        fontWeight: 'bold', 
                        textAlign: 'center' 
                      }}
                    >
                      Dispositivo
                    </TableCell>
                    <TableCell
                      sx={{ 
                        fontFamily: 'OCR A Extended', 
                        fontWeight: 'bold', 
                        textAlign: 'center' 
                      }}
                    >
                      Tipo
                    </TableCell>
                    <TableCell
                      sx={{ 
                        fontFamily: 'OCR A Extended', 
                        fontWeight: 'bold', 
                        textAlign: 'center' 
                      }}
                    >
                      Data/Hora
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notificacoesPagina.map((notificacao) => (
                    <TableRow key={notificacao.id}>
                      <TableCell 
                        sx={{ 
                          fontFamily: 'OCR A Extended', 
                          textAlign: 'center' 
                        }}
                      >
                        {notificacao.gatewayNome}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          fontFamily: 'OCR A Extended', 
                          textAlign: 'center' 
                        }}
                      >
                        {notificacao.descricao}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          fontFamily: 'OCR A Extended', 
                          textAlign: 'center' 
                        }}
                      >
                        {notificacao.item}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          fontFamily: 'OCR A Extended', 
                          textAlign: 'center' 
                        }}
                      >
                        {notificacao.tipo}
                      </TableCell>
                      <TableCell 
                        sx={{ 
                          fontFamily: 'OCR A Extended', 
                          textAlign: 'center' 
                        }}
                      >
                        {notificacao.createdAt
                          ? format(new Date(notificacao.createdAt), 'dd/MM/yyyy HH:mm:ss')
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                   
                  {emptyRows > 0 &&
                    Array.from({ length: emptyRows }).map((_, index) => (
                      <TableRow key={`empty-${index}`} style={{ height: 53 }}>
                        <TableCell colSpan={5} />
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
           
              <TablePagination
                rowsPerPageOptions={[6]}
                component="div"
                count={notificacoes.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </TableContainer>
            <Box
            sx={{
              mt: 2,
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              gap:2, 
            }}
          >
            <Button
              variant="contained"
              onClick={downloadNotificacoes}
              startIcon={<Download />}
              sx={{
                backgroundColor: '#033b57',
                height: 55,
                fontFamily: 'OCR A Extended',
                maxWidth: 350,
                width: '100%',
              }}
            >
              Donwload 
            </Button>
            {/*<Button
              variant="contained"
              onClick={abrirDialog}
              startIcon={<Delete />}
              sx={{
                backgroundColor: '#033b57',
                height: 55,
                fontFamily: 'OCR A Extended',
                maxWidth: 350,
                width: '100%',
              }}
            >
              Limpar Registro de Notificações
            </Button>*/}
          </Box>
          </Container>
          
          <Dialog
            open={openDialog}
            onClose={fecharDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle 
              id="alert-dialog-title" 
              sx={{ 
                fontFamily: 'OCR A Extended' 
              }}
            >
              {"Deseja realmente excluir as notificações?"}
            </DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions>
              <Button
                onClick={fecharDialog}
                color="primary"
                sx={{ fontFamily: 'OCR A Extended' }}
              >
                Cancelar
              </Button>
              <Button
                onClick={confirmarExclusao}
                color="primary"
                autoFocus
                sx={{ fontFamily: 'OCR A Extended' }}
              >
                Confirmar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );

};

export default Notificacoes;


